// CONSTS 

const header = document.querySelector("header")
const header_height = header.offsetHeight
// const hamburger_button = document.querySelector("header button")

const logo_path = document.querySelector(".logo path")
// const hamburger_path = document.querySelector(".hamburger path")

const utility = document.querySelector("header a.utility")
const collab = document.querySelector("header a.collab")
const games = document.querySelector("header a.games")
const team = document.querySelector("header a.team")
const academy = document.querySelector("header a.academy")
const social_media_widget = document.querySelector(".social-media-widget")

const element = document.getElementsByClassName('bg-white')
let lastScrollTop = 0

// OBSERVER 

const bg_white = document.querySelector('.bg-white')

let bg_white_height = 0

if (bg_white) {
  bg_white_height = bg_white.offsetHeight
}

function changeBgWhiteColorSchema() {

  const bounding = bg_white.getBoundingClientRect()

  if (bounding.top <= 70) {
    $('header').addClass('header-dark')
  } else if (bounding.top <= 500) {
    $('header').removeClass('header-dark')
    $(".social-media-widget").addClass('dark')
  } else {
    $(".social-media-widget").removeClass('dark')

  }

}

// HEADER SCROLL 

let scrollpos = window.scrollY

const add_class_on_scroll = () => header.classList.add("blurred")
const remove_class_on_scroll = () => header.classList.remove("blurred")

window.addEventListener('scroll', function() {

  if(bg_white) {
    changeBgWhiteColorSchema()
  }

  scrollpos = window.scrollY

  if (scrollpos >= header_height) { add_class_on_scroll() }
  else { remove_class_on_scroll() }

})

// MENU 

document.addEventListener("DOMContentLoaded", function(){

  gsap.from(".hero-text", {
    y: 100, 
    opacity: 0,
    delay: .5,
    duration: 1.75,
    filter:"blur(8px)",
    ease: "slow(0.5, 0.8, true)"
  })
  
  const heroText = gsap.timeline({
    scrollTrigger: {
      trigger: "#hero",
      toggleActions: "restart pause reverse reverse",
      start: "top top",
      end: "+=250px",
      scrub: 1,
    },
  })
  
  heroText.fromTo(".hero-text", { y: 0 }, { y: -150 })

  if (window.location.href.indexOf("utility") > -1) {
    utility.classList.add("active")
  } else if (window.location.href.indexOf("collab") > -1) {
    collab.classList.add("active") 
    // hamburger_button.classList.add("gradient")
  } else if (window.location.href.indexOf("games") > -1) {
    games.classList.add("active")
    header.classList.add("header-dark")
    social_media_widget.classList.add("dark")
  } else if (window.location.href.indexOf("legal-documents") > -1) {
    header.classList.add("header-dark")
    social_media_widget.classList.add("dark")
  } else if (window.location.href.indexOf("facebook-data-deletion-instructions") > -1) {
    header.classList.add("header-dark")
    social_media_widget.classList.add("dark")
  } else if (window.location.href.indexOf("team") > -1) {
    team.classList.add("active")
    // hamburger_button.classList.add("gradient")
  } else if (window.location.href.indexOf("academy") > -1) {
    academy.classList.add("active") 
    // hamburger_button.classList.add("gradient")
  } else  {
    return false
  }

})

// MOBILE MENU 


$(".hamburger").on( "click", function() {
  $('.mobile-menu').removeClass("hide")
  $('body').addClass("mobile-overflow-hidden")
});

$(".x").on( "click", function() {
  $('.mobile-menu').addClass("hide")
  $('body').removeClass("mobile-overflow-hidden")
});

// FANCY SLIDE TEXT ON SCROLL 

const tl1 = gsap.timeline({
  scrollTrigger: {
    trigger: ".slideTextTrigger",
    toggleActions: "restart pause reverse reverse",
    start: "-=500",
    end: "+=1000",
    scrub: true,
  },
})

tl1.fromTo(".animeTextLeft", { x: '-50%' }, { x: '110%' })

const tl2 = gsap.timeline({
  scrollTrigger: {
    trigger: ".slideTextTrigger",
    toggleActions: "restart pause reverse reverse",
    start: "-=500",
    end: "+=1000",
    scrub: true,
  },
})

tl2.fromTo(".animeTextRight", { x: '50%' }, { x: '-110%' })

// FADE IN COL 

const fadeInColTl = gsap.timeline({
  scrollTrigger: {
    trigger: ".fade-in-col-trigger",
    toggleActions: "restart pause reverse reverse",
    start: "-=550px",
  },
})

fadeInColTl.from('.fade-in-col', {
  opacity: 0,
  y: 100,
  stagger: .5,
})

// PARALLAX PATTERN 

gsap.to('.parallax', { 
  scrollTrigger: {
    trigger: ".parallax-trigger",
    toggleActions: "restart pause reverse reverse",
    start: "-=250px",
    // end: "+=750px",
    scrub: 15,
  },
  y: -200, 
})